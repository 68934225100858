import React from 'react';
import { Card, CardContent } from "@mui/material";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab//LoadingButton";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { config } from "../../config/main"
import { toast } from 'react-toastify';

const validationSchema = yup.object({
    phone_no: yup.string().required('Phone number is required').length(10, "Phone number must be 10 digit long"),
});

const Login = () => {
    const navigate = useNavigate();

    const handleSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true)
        try {
            const { data } = await axios.post(`${config.baseUrl}/admin/login`, values)
            if (!data.error) {
                toast.success("OTP sent successfully!")
                localStorage.setItem("phone_no", data.phone_no)
                navigate("/verify-otp")
            } else {
                toast.error(data.title)
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong!")
        }
        setSubmitting(false)
    };

    return (
        <Div sx={{
            width: 500,
            maxWidth: '100%',
            margin: 'auto',
            p: 4
        }}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <CardContent sx={{ flex: 1, p: 4 }}
                >
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            phone_no: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
                                <Div sx={{ mt: 1, mb: 3, display: 'flex', justifyContent: "center" }}>
                                    <img src='images/logo.png' alt='Logo' height={120} />
                                </Div>
                                <Div sx={{ mt: 1, mb: 3 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="phone_no"
                                        label="Phone number"
                                        type="number"
                                    />
                                </Div>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isSubmitting}
                                >SEND OTP</LoadingButton>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        </Div>
    );
};

export default Login;

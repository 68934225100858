import React from "react";
import Dashboard from "../pages/dashboard";
import Page from "@jumbo/shared/Page";
import Login from "../pages/login";
import Locations from "app/pages/locations/Locations";
import VerifyOtp from "../pages/verifyOtp";
import { Navigate } from "react-router-dom";
import Customers from "app/pages/customers/Customers";
import Trips from "app/pages/trips/Trips";
import Transactions from "app/pages/transactions/Transactions";
import Drivers from "app/pages/drivers/Drivers";
import DriverDetails from "app/pages/driverDetails/DriverDetails";
import TripDetails from "app/pages/tripDetails/TripDetails";

const routesForPublic = [
    {
        path: "/login",
        element: <Page component={Login} layout={"solo-page"} />
    },
    {
        path: "/verify-otp",
        element: <Page component={VerifyOtp} layout={"solo-page"} />
    },
];

const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Navigate to={"/dashboard"} />
    },
    {
        path: "/dashboard",
        element: <Page component={Dashboard} layout={"vertical-default"} />
    },
    {
        path: "/locations",
        element: <Page component={Locations} layout={"vertical-default"} />
    },
    {
        path: "/customers",
        element: <Page component={Customers} layout={"vertical-default"} />
    },
    {
        path: "/trips",
        element: <Page component={Trips} layout={"vertical-default"} />
    },
    {
        path: "/transactions",
        element: <Page component={Transactions} layout={"vertical-default"} />
    },
    {
        path: "/drivers",
        element: <Page component={Drivers} layout={"vertical-default"} />
    },
    {
        path: "/drivers/details",
        element: <Page component={DriverDetails} layout={"vertical-default"} />
    },
    {
        path: "/trips/details",
        element: <Page component={TripDetails} layout={"vertical-default"} />
    },
];

const routesForNotAuthenticatedOnly = [];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly };
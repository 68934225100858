import React from 'react';
import * as yup from "yup";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import axios from 'axios';
import { config } from 'app/config/main';
import { toast } from 'react-toastify';

const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Enter a valid email'),
    phone_no: yup.string().length(10, "Phone number must be 10 digit long").required('Phone number is required'),
    admin: yup.string().required('Location admin is required'),
    address: yup.string().required('Address is required'),
    prefix: yup.string().length(3, "Prefix must be 3 characters long").required('Prefix is required'),
});
const initialValues = {
    name: "",
    phone_no: "",
    email: "",
    admin: "",
    address: "",
    prefix: "",
};

const AddLocation = ({ refreshData }) => {
    const onLocationSave = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            const { data } = await axios.post(`${config.baseUrl}/locations`, values, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                toast.success("Location created successfully!")
                refreshData()
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setSubmitting(false);
    };

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onLocationSave}
        >
            {({ isSubmitting }) => (
                <Form noValidate autoComplete="off">
                    <Div
                        sx={{
                            '& .MuiTextField-root': {
                                mb: 3
                            },
                        }}
                    >
                        <JumboTextField fullWidth size="small" variant="outlined" name="name" label="Name" />
                        <JumboTextField fullWidth size="small" variant="outlined" name="phone_no" label="Phone Number" type="number" />
                        <JumboTextField fullWidth size="small" variant="outlined" name="email" label="Email" type="email" />
                        <JumboTextField fullWidth size="small" variant="outlined" name="admin" label="Location admin" />
                        <JumboTextField fullWidth size="small" variant="outlined" name="address" label="Address" multiline InputProps={{ rows: 3 }} />
                        <JumboTextField fullWidth size="small" variant="outlined" name="prefix" label="Ticket no. prefix" inputProps={{ maxLength: 3, style: { textTransform: "uppercase" } }} onKeyDown={(e) => { if (!(/^[a-zA-Z]+$/.test(e.key))) { e.preventDefault() } }} />
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            loading={isSubmitting}
                        >Save</LoadingButton>
                    </Div>
                </Form>
            )}
        </Formik>
    );
};

export default AddLocation;

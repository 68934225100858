import React, { useEffect, useState } from "react";
import axios from 'axios';
import { config } from '../../config/main';
import DataTable from "../../shared/DataTable";
import './index.css';
import { CircularProgress, Button, ButtonGroup, Typography } from '@mui/material';
import AddLocation from "./AddLocation";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { toast } from "react-toastify";
import EditLocation from "./EditLocation";
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import Chip from "@mui/material/Chip";

const Locations = () => {
    const { showDialog, hideDialog } = useJumboDialog();
    const [loading, setLoading] = useState(false)
    const [locationsData, setLocationsData] = useState([])
    const [locationAdminData, setLocationManagerData] = useState([])
    const [tableData, setTableData] = useState([])
    const [page, setPage] = useState(1)
    const [searchedText, setSearchedText] = useState("")

    const columns = [
        {
            name: "name",
            label: "Name",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "admin",
            label: "Admin",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "phone",
            label: "Phone",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
            },
        }
    ];

    const options = {
        searchPlaceholder: 'Press Enter to Search',
        filterType: 'dropdown',
        responsive: 'scroll',
        viewColumns: false,
        rowsPerPage: tableData.length,
        page,
        rangePage: true,
        print: false,
        download: false,
        filter: false,
        search: true,
        sort: false,
        count: tableData.length,
        serverSide: true,
        server: true,
        selectableRowsOnClick: false,
        selectableRows: 'none',
        fixedHeader: false,
        searchText: searchedText,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    break;
                case 'changeRowsPerPage':
                    break;
                case 'search':
                    onkeypress = (e) => {
                        if (e.key == 'Enter') {
                            getLocations(tableState.searchText)
                            setSearchedText(tableState.searchText)
                        }
                    }
                    break;
                case 'onSearchClose':
                    setSearchedText("")
                    getLocations()
                    break;
                case 'onFilterDialogClose':
                    break
                case 'onFilterDialogOpen':
                    break;
                default:
                    break;
            }
        },
    }

    const getLocations = async (search) => {
        setLoading(true)
        try {
            const { data } = await axios.get(`${config.baseUrl}/locations/all?search=${search}`, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                setLocationsData(data.locationsData || [])
                setLocationManagerData(data.locationAdminData || [])
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoading(false)
    }

    const refreshData = () => {
        hideDialog()
        getLocations()
    }

    const deleteLocation = async (id) => {
        setLoading(true)
        try {
            const { data } = await axios.post(`${config.baseUrl}/locations/delete`, { id }, {
                headers: { token: localStorage.getItem("token") }
            })
            if (!data.error) {
                toast.success("Location deleted successfully!")
                refreshData()
            } else {
                toast.error(data.title || "Something went wrong")
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong")
        }
        setLoading(false)
    }

    const handleAddClick = () => {
        showDialog({
            title: 'Add location',
            content: <AddLocation refreshData={refreshData} />
        });
    }

    const handleEditClick = (data, adminData) => {
        showDialog({
            title: 'Edit location',
            content: <EditLocation refreshData={refreshData} location={data} admin={adminData} />
        });
    }

    const handleDeleteClick = (data) => {
        showDialog({
            variant: 'confirm',
            title: 'Are you sure about deleting this location?',
            content: "You won't be able to recover this location later.",
            onYes: () => deleteLocation(data._id),
            onNo: hideDialog
        })
    }

    useEffect(() => {
        getLocations()
    }, [])

    useEffect(() => {
        if (locationsData && locationsData.length > 0) {
            let data = []
            for (const val of locationsData) {
                const admin = locationAdminData.filter(v => String(v.location_id) === String(val._id))
                const adminData = admin && admin[0] ? admin[0] : {}
                data.push({
                    name: val.name,
                    admin: adminData.name,
                    phone: adminData.phone_no,
                    status: <Chip size={"small"} label={val.is_hidden ? "Hidden" : "Active"} color={val.is_hidden ? "secondary" : "success"} />,
                    action: <ButtonGroup color="primary" aria-label="outlined primary button group">
                        <Button variant="outlined" onClick={(e) => handleEditClick(val, adminData)} className={'text-warning'}>
                            Edit
                        </Button>
                        <Button variant="outlined" onClick={(e) => handleDeleteClick(val)} className={'text-success'}>
                            Delete
                        </Button>
                    </ButtonGroup>
                })
            }
            setTableData(data)
        } else {
            setTableData([])
        }
    }, [locationsData, locationAdminData])

    return (
        <React.Fragment>
            <PageHeader
                title={<Typography fontSize={"30px"} variant="h6" color="text.primary" mb={.5}>Locations</Typography>}
            />
            {
                loading ?
                    <div className="loader-view" style={{ height: 'calc(100vh - 259px)' }}>
                        <CircularProgress />
                    </div>
                    :
                    <DataTable
                        title={<Button color='primary' variant="outlined" onClick={handleAddClick}> Add Location +</Button>}
                        data={tableData}
                        columns={columns}
                        options={options}
                    />
            }
        </React.Fragment>
    );
}

export default Locations;
import React, { useEffect } from 'react';
import { Card, CardContent } from "@mui/material";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab//LoadingButton";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { config } from "../../config/main"
import { toast } from 'react-toastify';

const validationSchema = yup.object({
    otp: yup.string().required('OTP is required').length(4, "OTP must be 4 digit long"),
});

const VerifyOtp = () => {
    const navigate = useNavigate();

    const handleSubmit = async (values, { setSubmitting }) => {
        values.phone_no = localStorage.getItem("phone_no")
        setSubmitting(true)
        try {
            const { data } = await axios.post(`${config.baseUrl}/admin/verify_otp`, values)
            if (!data.error) {
                localStorage.removeItem("phone_no")
                localStorage.setItem("token", data.token)
                toast.success("User logged in successfully!")
                navigate("/dashboard")
            } else {
                toast.error(data.title)
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong!")
        }
        setSubmitting(false)
    };

    useEffect(() => {
        if (!localStorage.getItem("phone_no")) {
            navigate("/login")
        }
    }, [])

    return (
        <Div sx={{
            width: 500,
            maxWidth: '100%',
            margin: 'auto',
            p: 4
        }}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <CardContent sx={{ flex: 1, p: 4 }}
                >
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            otp: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
                                <Div sx={{ mt: 1, mb: 3, display: 'flex', justifyContent: "center" }}>
                                    <img src='images/logo.png' alt='Logo' height={120} />
                                </Div>
                                <Div sx={{ mt: 1, mb: 3 }}>
                                    <JumboTextField
                                        fullWidth
                                        name="otp"
                                        label="OTP"
                                        type="number"
                                    />
                                </Div>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isSubmitting}
                                >VERIFY OTP</LoadingButton>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        </Div>
    );
};

export default VerifyOtp;

import React from "react";
import HouseIcon from '@mui/icons-material/House';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import FlightIcon from '@mui/icons-material/Flight';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const menus = [
    {
        uri: "/dashboard",
        label: 'Dashboard',
        type: "nav-item",
        icon: <HouseIcon sx={{ fontSize: 20 }} />
    },
    {
        uri: "/locations",
        label: 'Locations',
        type: "nav-item",
        icon: <LocationOnIcon sx={{ fontSize: 20 }} />
    },
    {
        uri: "/customers",
        label: 'Customers',
        type: "nav-item",
        icon: <AccountBoxIcon sx={{ fontSize: 20 }} />
    },
    {
        uri: "/drivers",
        label: 'Drivers',
        type: "nav-item",
        icon: <DriveEtaIcon sx={{ fontSize: 20 }} />
    },
    {
        uri: "/trips",
        label: 'Trips',
        type: "nav-item",
        icon: <FlightIcon sx={{ fontSize: 20 }} />
    },
    // {
    //     uri: "/transactions",
    //     label: 'Transactions',
    //     type: "nav-item",
    //     icon: <CreditCardIcon sx={{ fontSize: 20 }} />
    // },
];

export default menus;

import React from 'react';
import Avatar from "@mui/material/Avatar";
import { ListItemIcon, ListItemText, ThemeProvider, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import EditProfile from './EditProfile';

const AuthUserDropdown = () => {
    const navigate = useNavigate();
    const { theme } = useJumboTheme();
    const { onLogout, userData } = useJumboAuth();
    const { showDialog, hideDialog } = useJumboDialog();

    const logout = () => {
        onLogout();
        navigate("/login");
    };

    const handleEditClick = () => {
        showDialog({
            title: 'Edit profile',
            content: <EditProfile hideDialog={hideDialog} />
        });
    }

    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={
                    <Avatar
                        src={userData.profile_pic}
                        sizes={"small"}
                        sx={{ boxShadow: 25, cursor: 'pointer' }}
                    >
                        {userData.name ? userData.name[0] : ''}
                    </Avatar>
                }
            >
                <Div sx={{
                    minWidth: '200px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: theme => theme.spacing(2.5),
                }}>
                    <Avatar src={userData.profile_pic} alt={userData.name} sx={{ width: 60, height: 60, mb: 2 }} >{userData.name ? userData.name[0] : ''}</Avatar>
                    <Typography variant={"h5"}>{userData.name}</Typography>
                    <Typography variant={"body1"} color="text.secondary">{userData.phone_no}</Typography>
                    {
                        userData.email && <Typography variant={"body1"} color="text.secondary">{userData.email}</Typography>
                    }
                </Div>
                <Divider />
                <nav>
                    <List disablePadding sx={{ pb: 1 }}>
                        <ListItemButton onClick={handleEditClick}>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <EditOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Edit Profile" sx={{ my: 0 }} />
                        </ListItemButton>
                        <ListItemButton onClick={logout}>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" sx={{ my: 0 }} />
                        </ListItemButton>
                    </List>
                </nav>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

export default AuthUserDropdown;
